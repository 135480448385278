require('intersection-observer'); // 遅延読み込みのpolyfill
import lozad from 'lozad' // 画像・動画遅延読み込み用ライブラリ
import objectFitImages from 'object-fit-images'; // object-fit のpolyfill
let isPCsize = true
// ウインドウの横幅を保持
let currentWidth = window.innerWidth;
// スクロールでリサイズイベント発生を防ぐ
// 参考：https://qiita.com/tonkotsuboy_com/items/d32ec6e7a1f6f592d415

// let doneChangeMovie

// ==========
// DOMツリー構築終了後に実行
// ==========
document.addEventListener('DOMContentLoaded', function() {
"use strict";

}, false);

// ==========
// リサイズ処理
// ==========
let resizeTimeoutId; // リサイズイベントの頻度を減らす方法
window.addEventListener('resize',function(){
  "use strict";
  // setTimeout()がセットされていたら無視
	if ( resizeTimeoutId ) return ;
	resizeTimeoutId = setTimeout( function () {
    resizeTimeoutId = 0 ;

    // ----------- 処理内容 ----------- //


      // ----- サイズでの条件分岐用 ----- //
      let browserWidth = document.documentElement.scrollWidth;
      // SPサイズ以下の処理
      if(browserWidth <= 1025) {
        isPCsize = false;
        // ウインドウ横幅が変わっていないため処理をキャンセル。
        if (currentWidth == window.innerWidth) { return; }

      }
      // PCサイズ以上の処理
      else if(!isPCsize) {
        isPCsize = true;
        // ウインドウ横幅が変わっていないため処理をキャンセル。
        if (currentWidth == window.innerWidth) { return; }
      }
  
    // ----------- 処理内容ここまで ------------- //
  }, 500);
}, false);


// ==========
// DOM、画像読み込み後に実行
// ==========
window.addEventListener('load',function(){
"use strict"
  // IE object-fit用
  objectFitImages();

  // 遅延読み込み準備
  const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  observer.observe();

  // ----- サイズでの条件分岐用 ----- //
  let browserWidth = document.documentElement.scrollWidth;
  // SPサイズ以下の処理
  if(browserWidth <= 1025) {
    isPCsize = false;
    // ウインドウ横幅が変わっていないため処理をキャンセル。
    if (currentWidth == window.innerWidth) { return; }

  }
  // PCサイズ以上の処理
  else if(!isPCsize) {
    isPCsize = true;
    // ウインドウ横幅が変わっていないため処理をキャンセル。
    if (currentWidth == window.innerWidth) { return; }
  }

}, false)


// ==========
// スクロール時に実行
// ==========
let scrollTimeoutId; // スクロールイベントの頻度を減らす方法
// スクロールジャンク防止の passive: true の設定
// IE11だけは第3引数にセットすると、別のプロパティ(useCapture)がtrueになるため、下記のコードで非対応ブラウザかどうか判定する。
// 参考URL：https://b.0218.jp/20180714221323.html
let passiveSupported = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, "passive", { get: function() { passiveSupported = true; } }));
} catch(err) {}

passiveSupported = passiveSupported ? { passive: true } : false;

window.addEventListener('scroll', () => {
  // スクロールイベントの頻度を減らす。
  if (scrollTimeoutId) return;
  scrollTimeoutId = setTimeout( () => {
    scrollTimeoutId = 0 ;

    // ----------- 処理内容 ----------- //


      // ----- サイズでの条件分岐用 ----- //
      let browserWidth = document.documentElement.scrollWidth;
      // SPサイズ以下の処理
      if(browserWidth <= 1025) {
        isPCsize = false;
        // ウインドウ横幅が変わっていないため処理をキャンセル。
        if (currentWidth == window.innerWidth) { return; }

      }
      // PCサイズ以上の処理
      else if(!isPCsize) {
        isPCsize = true;
        // ウインドウ横幅が変わっていないため処理をキャンセル。
        if (currentWidth == window.innerWidth) { return; }
      }
  
    // ----------- 処理内容ここまで ------------- //

  }, 500);
}, passiveSupported);